<template>
  <div
    class="widget"
    :class="[
      `theme-${theme}`,
      `show-fiat-${showFiat}`,
      `show-latest-${showLatest}`,
      {'display-qr': displayQr},
    ]"
  >
    <div class="loading" v-if="!partner && partnerFound !== false">
      <Loading />
      <span>loading cuddles</span>
    </div>
    <div class="loading" v-if="partnerFound === false">
      <span>partner not found</span>
    </div>
    <div v-if="partner">
      <div class="wrap">
        <div class="main">
          <QR
            :partner="partner"
            :client="client"
            @close="onQrClose"
            v-if="displayQr"
          />
          <div class="logo">
            <a href="https://cuddle.me/fund" target="_blank"
              ><CuddlePartnerLogo class="logo-image" />
              <span class="cuddle-website">cuddle.me/fund</span>
            </a>
          </div>
          <div class="logo-donations">
            <div class="logo">
              <a href="https://cuddle.me/fund" target="_blank"
                ><CuddlePartnerLogo class="logo-image" />
                <span class="cuddle-website">cuddle.me/fund</span>
              </a>
            </div>
            <div class="donations">
              <div class="total">
                <span class="label">donations received</span>
                <div class="amount-and-symbol">
                  <EthereumSymbol class="ethereum-symbol" />
                  <span class="amount">{{
                    roundAmount(partner.totalDonations.ETH, 3)
                  }}</span>
                </div>
                <div class="amount-fiat" v-if="showFiat">
                  ${{ totalDonationsUSD }}
                </div>
              </div>
            </div>
          </div>
          <LatestDonations
            :latestDonations="partner.latestDonations"
            :client="client"
            v-if="showLatest"
          />
        </div>
        <PartnerAddress
          :partner="partner"
          :client="client"
          @displayQr="onQrOpen"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {db} from "@/globals"
import Loading from "@/components/Loading"
import mixinGlobal from "@/mixins/mixinGlobal"
import CuddlePartnerLogo from "@/assets/cuddle_partner_logo_color.svg"
import EthereumSymbol from "@/assets/ethereum-symbol.svg"
import PartnerAddress from "@/components/PartnerAddress"

export default {
  name: "Widget",
  mixins: [mixinGlobal],
  props: {
    partnerId: {
      type: String,
      default: "",
    },
    theme: {
      type: String,
      default: "pink", // pink | cream | clean
    },
    showFiat: {
      type: Boolean,
      default: true,
    },
    showLatest: {
      type: Boolean,
      default: true,
    },
    client: {
      type: Object,
    },
  },
  components: {
    Loading,
    CuddlePartnerLogo,
    EthereumSymbol,
    PartnerAddress,
    LatestDonations: () =>
      import(
        /* webpackChunkName: "latestDonations" */ "@/components/LatestDonations"
      ),
    QR: () => import(/* webparckChunkName: "QR" */ "@/components/QR"),
  },
  data() {
    return {
      partnerFound: null,
      partner: null,
      exchangeRate: null,
      displayQr: false,
    }
  },
  computed: {
    totalDonationsUSD() {
      return this.roundAmount(
        this.exchangeRate * this.partner.totalDonations.ETH,
        0
      ).toLocaleString()
    },
  },
  mounted() {
    if (this.showFiat) this.subscribeExchangeRate()
    this.subscribePartner()
  },
  beforeDestroy() {
    if (this.showFiat) this.unsubscribeExchangeRate()
    this.unsubscribePartner()
  },
  methods: {
    onQrOpen() {
      this.displayQr = true
    },
    onQrClose() {
      this.displayQr = false
    },
    subscribeExchangeRate() {
      this.unsubscribeExchangeRate = db
        .doc(`exchangeRates/latest`)
        .onSnapshot((doc) => {
          if (doc) {
            this.exchangeRate = doc.data().pairs['ETH_USD']
          } else {
            console.error("exchange rate not found")
          }
        })
    },
    subscribePartner() {
      console.log("subscribePartner")
      this.unsubscribePartner = db.doc(`partners/${this.partnerId}`).onSnapshot(
        (doc) => {
          this.partnerFound = doc.exists
          if (doc.exists) {
            this.partner = doc.data()
            this.$nextTick(() => {
              this.$emit("partnerLoaded")
            })
            // once more to be sure
            setTimeout(() => {
              this.$emit("partnerLoaded")
            }, 500)
          }
        },
        (error) => {
          console.error(error)
        }
      )
    },
  },
}
</script>

<style lang="less">
.widget {
  font-family: "Outfit";
  font-weight: 400;
  background: var(--color-widget-bg);
  border-radius: 12px;
  position: relative;
  min-width: 330px;
  min-height: 100px;

  // @media screen and (max-width: 430px) {
  //   border-radius: 12px;

  //   &.show-latest-true {
  //     span.cuddle-website {
  //       display: none;
  //     }
  //   }

  //   .wrap {
  //     .main {
  //       // padding: 4px 8px !important;
  //       justify-content: flex-end;
  //       overflow: visible !important;
  //       .logo {
  //         position: absolute;
  //         top: 26px;
  //         left: 20px;
  //         z-index: 1;
  //       }
  //     }
  //     .donations {
  //       .total {
  //         .amount-and-symbol {
  //           .amount {
  //             font-size: 2rem !important;
  //           }
  //           .ethereum-symbol {
  //             height: 2rem !important;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  // &.show-latest-false {
  //   .wrap {
  //     .main {
  //       .logo {
  //         .logo-image {
  //           width: 130px;
  //         }
  //         span.cuddle-website {
  //           display: none;
  //         }
  //       }
  //     }
  //   }
  // }

  &.display-qr {
    // @media screen and (max-width: 365px) {
    //   .wrap {
    //     .main {
    //       .logo {
    //         opacity: 0;
    //       }
    //     }
    //     .partner-address {
    //       opacity: 0;
    //     }
    //   }
    // }
  }

  &.show-latest-false {
    .wrap {
      .main {
        > .logo {
          display: none !important;
        }
        .logo-donations {
          > .logo {
            display: flex !important;
          }
        }
      }
    }
  }

  &.show-latest-true {
    .wrap {
      .main {
        .logo {
          display: none;
          flex-direction: column;
          justify-content: center;

          @media screen and (min-width: 450px) {
            display: flex;
            width: calc(28vw + 1rem);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
          }

          a {
            text-decoration: none;
            font-size: 1rem;
            color: var(--color-main-logo-label);
            display: flex;
            flex-direction: column;
            align-items: center;
            .logo-image {
              width: calc(20vw + 2.5rem);
            }
            span.cuddle-website {
              font-size: 1rem;
              padding-top: 8px;
            }
          }
        }
      }
    }
  }

  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-main-logo-label);
    min-height: 100px;
  }

  .wrap {
    .main {
      position: relative;
      // display: flex;
      // flex-direction: row;
      // padding: 20px;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      .logo-donations {
        display: flex;
        flex-direction: row;
        padding: 10px;
        .logo {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          flex: 1;
          padding-right: 20px;

          @media screen and (min-width: 450px) {
            display: none;
          }

          a {
            text-decoration: none;
            font-size: 1rem;
            color: var(--color-main-logo-label);
            display: flex;
            flex-direction: column;
            align-items: center;
            .logo-image {
              width: 100px;
            }
            span.cuddle-website {
              font-size: 1rem;
              padding-top: 8px;
            }
          }
        }
        .donations {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .total {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            // padding: 6px;
            font-size: 1rem;

            .label {
              color: var(--color-main-donations-label);
            }

            .amount-and-symbol {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              .amount {
                font-size: 3rem;
                display: block;
                color: var(--color-main-donations-amount);
              }

              .ethereum-symbol {
                height: 3rem;
                padding-right: 10px;
              }
            }

            .amount-fiat {
              font-size: 1.5rem;
              color: var(--color-main-donations-amount-fiat);
            }
          }
        }
      }
    }
  }
}
</style>
