export default {
  methods: {
    truncateAddress(address) {
      if (!address) return
      return `${address.substring(0, 6)}...${address.substring(
        address.length - 4
      )}`
    },
    roundAmount(amount, decimals = 3) {
      let tralala = Math.pow(10, decimals)
      return Math.round(amount * tralala) / tralala
    },
    copyToClipboard(content) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(content).then(
          () => {
            console.log("Copied to clipboard successfully.")
          },
          (err) => {
            console.log("Failed to copy the text to clipboard.", err)
          }
        )
      } else if (window.clipboardData) {
        window.clipboardData.setData("Text", content)
      }
    },
  },
}
