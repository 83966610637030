<template>
  <div class="partner-address">
    <div class="inner">
      <div class="address-copied" :class="{visible: addressCopied}">
        <span>address copied to clipboard</span>
      </div>
      <span class="label">{{ partner.name }} eth address:</span>
      <div class="address-and-icon">
        <span class="address">{{ partnerEthAddress }}</span>
        <div class="icons">
          <IconCopy class="icon-copy" @click="copyPartnerAddress()" />
          <IconQr class="icon-qr" @click="displayQr" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixinGlobal from "@/mixins/mixinGlobal"
import IconCopy from "@/assets/icon-copy.svg"
import IconQr from "@/assets/icon-qr.svg"

export default {
  name: "PartnerAddress",
  mixins: [mixinGlobal],
  props: {
    partner: {
      type: Object,
    },
    client: {
      type: Object,
    },
  },
  components: {
    IconCopy,
    IconQr,
  },
  data() {
    return {
      truncatedAddressBreakpoint: 485,
      addressCopied: false,
    }
  },
  computed: {
    partnerEthAddress() {
      if (this.client.viewport.width >= this.truncatedAddressBreakpoint)
        return this.partner.ethAddress
      else return this.truncateAddress(this.partner.ethAddress)
    },
  },
  created() {},
  beforeDestroy() {},
  methods: {
    displayQr() {
      this.$emit("displayQr")
    },
    copyPartnerAddress() {
      this.copyToClipboard(this.partner.ethAddress)
      this.addressCopied = true
      setTimeout(() => {
        this.addressCopied = false
      }, 2500)
    },
  },
}
</script>

<style lang="less" scoped>
.partner-address {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;

  // @media screen and (max-width: 430px) {
  //   align-items: flex-end;

  //   .inner {
  //     border-radius: 12px 0px 12px 0px !important;
  //     padding: 4px 8px !important;
  //     text-align: left !important;

  //     .address-and-icon {
  //       .address {
  //         font-size: 1rem !important;
  //       }
  //       .icon-copy,
  //     .icon-qr {
  //       width: 16px !important;
  //     }
  //     }
  //   }
  // }

  .inner {
    background: var(--color-partner-address-bg);

    border-radius: 0px 0px 12px 12px;
    padding: 0px 0px 4px 10px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    text-align: left;

    .address-copied {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      opacity: 0;
      pointer-events: none;
      transition: all 0.5s 0s ease-in-out;
      z-index: 100;

      &.visible {
        opacity: 1;
      }

      span {
        color: var(--color-partner-address-popup-label);
        background: var(--color-partner-address-popup-bg);
        padding: 6px 8px;
        border-radius: 8px;
      }
    }

    .label {
      font-size: 0.75rem;
      text-transform: uppercase;
      color: var(--color-partner-address-label);
      white-space: nowrap;
    }

    .address-and-icon {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: center;
      
      .address {
        // font-size: 1.2rem;
        font-size: 1rem;
        color: var(--color-partner-address-address);
        letter-spacing: 1px;
      }
      .icons {
        position: absolute;
        bottom: 0px;
        top: 0px;
        right: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .icon-copy,
        .icon-qr {
          width: 24px;
          margin-left: 10px;
          cursor: pointer;
          transition: all 0.25s 0s ease-in-out;

          * {
            fill: var(--color-partner-address-icon);
          }

          &:hover {
            * {
              fill: var(--color-partner-address-icon-hover);
            }
          }

          &:active {
            * {
              fill: var(--color-partner-address-icon-active);
            }
          }
        }

        .icon-copy {
        }

        .icon-qr {
        }
      }
    }
  }

  @media screen and (min-width: 430px) {
    .inner {
      // padding: 10px 8px;
      // border-radius: 0 0 28px 28px;
    }
  }
}
</style>
