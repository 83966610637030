import Vue from 'vue'
import Vuex from 'vuex'

// also config in main.js
console.log('VUE_APP_FORCE_DEBUG', process.env.VUE_APP_FORCE_DEBUG)
if (['local_dev', 'development', 'staging'].includes(process.env.VUE_APP_ENVIRONMENT) || process.env.VUE_APP_FORCE_DEBUG == "true") {
  Vue.config.devtools = true
  Vue.config.productionTip = false
  Vue.config.silent = false
} else if (['production'].includes(process.env.VUE_APP_ENVIRONMENT)) {
  Vue.config.devtools = false
  Vue.config.productionTip = false
  Vue.config.silent = false
}

// modules

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    // client
  }
})

export default store