import Vue from "vue"

// also in store/index.js
if (
  ["local_dev", "development", "staging"].includes(
    process.env.VUE_APP_ENVIRONMENT
  ) ||
  process.env.VUE_APP_FORCE_DEBUG == "true"
) {
  Vue.config.devtools = true
  Vue.config.productionTip = false
  Vue.config.silent = false
} else if (["production"].includes(process.env.VUE_APP_ENVIRONMENT)) {
  Vue.config.devtools = false
  Vue.config.productionTip = false
  Vue.config.silent = false
}

import App from "./App.vue"
import store from "./store"
import '@/themes/themes.less'

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app")