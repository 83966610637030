<template>
  <Widget
    ref="widget"
    :partnerId="partnerId"
    :theme="theme"
    :showFiat="showFiat"
    :showLatest="showLatest"
    :client="client"
    @partnerLoaded="updateWidgetSize"
  />
</template>

<script>
import Widget from "@/views/Widget"
export default {
  name: "App",
  components: {
    Widget,
  },
  data() {
    return {
      partnerId: null,
      theme: undefined,
      showFiat: true,
      showLatest: true,
      client: {
        viewport: {
          width: null,
          height: null,
        },
        widget: {
          width: null,
          height: null
        }
      },
    }
  },
  created() {
    let url = window.location.href
    url = new URL(url)
    let partnerId = url.searchParams.get("partnerId")
    let theme = url.searchParams.get("theme")
    let showFiat = url.searchParams.get("showFiat")
    let showLatest = url.searchParams.get("showLatest")
    if (partnerId) this.partnerId = partnerId
    if (theme) this.theme = theme
    if (showFiat) this.showFiat = showFiat === "true"
    if (showLatest) this.showLatest = showLatest === "true"
    // console.log('app: partnerId:', partnerId)
  },
  watch: {
    
  },
  mounted() {
    this.updateVieportSize()
    this.updateWidgetSize()
    window.addEventListener("resize", () => {
      this.updateVieportSize()
      this.updateWidgetSize()
    })
  },
  methods: {
    updateWidgetSize() {
      if (!this.$refs || !this.$refs.widget) return
      this.client.widget.height = this.$refs.widget.$el.clientHeight
      this.client.widget.width = this.$refs.widget.$el.clientWidth
      this.messageIframe('heightChanged', this.client.widget)
    },
    updateVieportSize() {
      let vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      )
      let vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      )

      this.client.viewport.width = vw
      this.client.viewport.height = vh
    },
    messageIframe(event, payload) {
      window.parent.postMessage({
        event,
        payload
      }, "*")
    }
  },
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Outfit&display=swap");
html,
body {
  padding: 0;
  margin: 0;
}
</style>
