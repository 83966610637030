import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'

// Firebase
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}
firebase.initializeApp(firebaseConfig)

var fbsFn = firebase.functions()
var fbsDb = firebase.firestore()

// connect to emulator
if (process.env.VUE_APP_ENVIRONMENT == 'local_dev') {
  console.log('using emulator functions')
  console.log('using emulator firestore')
  console.log('using emulator auth')

  fbsFn.useEmulator('localhost', 5001)
  fbsDb.useEmulator('localhost', 8080)
} 

// exports
export const fb = firebase
export const db = fbsDb
export const fn = fbsFn