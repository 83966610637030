<template>
<div
  class='one-loading'
  :class="{'center': center}"
>
  <svg
    class="one-loading-img"
    :class="[`size-${size}`, `color-${color}`]"
  >
    <circle
      fill="transparent"
      :stroke-dasharray="circumference + ' ' + circumference"
      :style="{ strokeDashoffset }"
      :stroke-width="stroke"
      stroke-linecap="round"
      :r="normalizedRadius"
      :cx="radius"
      :cy="radius"
    />
  </svg>
</div>
</template>

<script>
export default {
  name: 'oneLoading',
  props: {
    size: {
      type: String,
      default: 'm'
    },
    center: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'default'
    }
  },
  components: {},
  data() {
    return {
      progress: 60
    }
  },
  computed: {
    radius() {
      if (this.size == 's') return 12
      if (this.size == 'm') return 18
      if (this.size == 'l') return 38
    },
    stroke() {
      if (this.size == 's') return 2
      if (this.size == 'm') return 3
      if (this.size == 'l') return 5
    },
    strokeDashoffset() {
      return this.circumference - this.progress / 100 * this.circumference;
    },
    normalizedRadius() {
      return this.radius - this.stroke * 2
    },
    circumference() {
      return this.normalizedRadius * 2 * Math.PI;
    }
  },
  created() {},
  methods: {},
}
</script>

<style scoped lang='less'>

@keyframes spin {
    100% {
        transform: rotate(360deg) translateZ(0);
    }
}

.one-loading {
  
  &.center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
    svg.one-loading-img {
        transition: stroke-dashoffset 0.35s;
        transform-origin: 50% 50%;
        animation: spin 1s linear infinite;

        &.color-default * {
            // stroke: #acacac;
            stroke: var(--color-main-logo-label);
        }

        &.color-white * {
            stroke: #fff;
        }

        &.color-dark * {
            stroke: #000;
        }

        &.size-s {
            width: 24px;
            height: 24px;
        }

        &.size-m {
            width: 36px;
            height: 36px;
        }

        &.size-l {
            width: 76px;
            height: 76px;
        }
    }
}
</style>